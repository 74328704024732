import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppWrapper } from "../components/AppWrapper";
import { BackgroundEntreprises } from "../components/Background/BackgroundEntreprises";
import { BackgroundSection } from "../components/Background/BackgroundSection";
import Footer from "../components/ui/Footer";
import { FormEntreprises } from "../components/FormEntreprises/FormEntreprises";
import { Header } from "../components/Header";
import Banner from "../components/ui/Banner";
import { FaRegHeart } from "react-icons/fa";
import { Hero } from "../components/ui/Hero";
import ResultsWrapper from "../components/sections/ResultsWrapper";
import firebase from "gatsby-plugin-firebase";
import { ANALYTIC_EVENT_NAMES } from "../constants/analytics";
import {graphql} from "gatsby";
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default function IndexPage({ location }) {
    const { t } = useTranslation();

    // Analytics - page viewed event
    useEffect(() => {
        if (!firebase) {
            return;
        }
        firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_page_viewed);
        firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_company_offer_page_viewed);
    }, [firebase]);

    return (
        <AppWrapper>
            <Header {...{ location: location.pathname }} />
            <Hero
                title={t("business.hero.title")}
                subtitle={t("business.hero.subtitle")}
                img="/images/hero/img.webp"
            />
            <BackgroundEntreprises>
                <Banner
                    addStyle="mb-16"
                    title={t("business.banner.title")}
                    ctaAnchor={t("business.banner.cta")}
                    ctaAction="https://happiz.onelink.me/5ePS/e6b33nm1"
                    img="/images/benefits/entreprise.webp"
                    ctaIcon={<FaRegHeart />}
                >
                    <p>
                        {t("business.banner.description")}
                    </p>
                </Banner>
                <ResultsWrapper />
            </BackgroundEntreprises>
            <BackgroundSection entreprise>
                <FormEntreprises />
            </BackgroundSection>
            <Footer />
        </AppWrapper>
    );
}
