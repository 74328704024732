import { Link } from "gatsby";
import React, { ReactElement, MouseEvent } from "react";
import { HiOutlineArrowSmRight } from "react-icons/hi";

interface btnProps {
    children: React.ReactNode;
    variant: 'primary' | 'icon' | 'arrow' | 'outlined'
    icon?: ReactElement;
    to: string;
    onClick?: (event: MouseEvent) => void;
}

const Button = ({ children, variant, icon, to, onClick }: btnProps) => {
    return (
        <Link
        to={to}
        className={`group font-semibold rounded-full px-10 py-3 text-center text-lg flex items-center gap-2 ${variant === "outlined" ? "border-2 border-ink text-ink" : "text-white bg-bluegrad hover:bg-lightbluegrad"}`}
        onClick={onClick}
        >
            {variant === 'arrow' && <HiOutlineArrowSmRight className="w-6 h-6 block group-hover:translate-x-1 transition-all"/>}
            <span className="text-center block w-full">{children}</span>
            {icon && (
                <div className="w-8">
                    {icon}
                </div>
            )}
        </Link>
    )
}

export default Button