import * as React from "react"

const SVGWave = (props) => {
    const p1 = { x: 0, y: 40 }
    const c1 = { x: 150, y: -40 }
    const c2 = { x: 200, y: 120 }
    const p2 = { x: 500, y: 60 }
    const p3 = { x: 500, y: 100 } // close path
    const p4 = { x: 0, y: 100 } // close path
    return (
        <svg
            height="100%"
            width="100%"
            viewBox="0 0 500 75" xmlns="http://www.w3.org/2000/svg" {...props}
            fontSize="6px"
        >
            {/* white wave */}
            <path
                d={`
                    M ${p1.x} ${p1.y - 3} 
                    C ${c1.x} ${c1.y} ${c2.x} ${c2.y} 
                    ${p2.x} ${p2.y - 30}
                    L ${p3.x} ${p3.y}
                    ${p4.x} ${p4.y}
                    `}
                fill="#fff6"
            />
            <path
                d={`
                    M ${p1.x} ${p1.y + 0} 
                    C ${c1.x} ${c1.y} ${c2.x} ${c2.y} 
                    ${p2.x} ${p2.y}
                    L ${p3.x} ${p3.y}
                    ${p4.x} ${p4.y}
                    `}
                fill="#F5F8FB"
            />

        </svg>
    )
}

export { SVGWave, }