import * as React from "react";
import formeDown from "./forme-down.png";

const BackgroundEntreprises = ({ children }: { children?: React.ReactNode }) => {
    return (
        <div
            id="BackgroundEntreprises"

            {...{
                style: {
                    backgroundColor: '#fff',
                    backgroundImage: (() => {
                        return `
                    linear-gradient(#F5F8FB, #A7BCDB)
                    `
                    })(),
                    display: "grid",
                    gridTemplate: `
                        ""
                    `,
                    // display: "grid",
                    // gridTemplateRows: "repeat(9, 1fr)",
                    // gridTemplateColumns: "repeat(3, 33.33vw)",
                    zIndex: -10,
                    overflow: "hidden",
                },
            }}
        >
            <img src={formeDown} alt="" style={{
                gridArea: "1/1/-1/-1",
                placeSelf: "start end",
                maxWidth: "25vw"

            }} />
            {/* <SVGWave2 style={{
                gridArea: "1/1/-1/-1",
                placeSelf: "end center",
            }} /> */}
            {/* <img src={formeUp} alt="" style={{
                // gridArea: "7/1/-1/2",
                gridArea: "1/1/-1/-1",
                placeSelf: "end start",
                maxWidth: "25vw",
                marginLeft: "-10vh",
                marginBottom: "-15vh"
            }} /> */}



            <div id=""
                className=""
                {...{
                    style: {
                        gridArea: "1/1/-1/-1",
                        display: "grid",
                        zIndex: 10,
                        placeSelf: "center"
                    },
                }}>

                {children}
            </div>
        </div>
    )
}

export { BackgroundEntreprises };
