import React, { useState, useEffect } from 'react';
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { useMobile } from "../../hooks/hooks.js";
import { WidthContainer } from '../WidthContainer';
import sphere from "./sphere.webp";
import "../../styles/styles.css";
import { toast } from "react-toastify";
import firebase from "gatsby-plugin-firebase";
import { ANALYTIC_EVENT_NAMES } from "../../constants/analytics";
import { BiMapPin } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";

const ContactForm = () => {
    const { t } = useTranslation();
    const { mobile } = useMobile();
    const [formSent, setFormSent] = useState(false);
    const [formUnsent, setFormUnsent] = useState(false);

    useEffect(() => {
        if (firebase && formSent) {
            firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_company_form_sent);
            setFormSent(false);
        } else if (firebase && formUnsent) {
            firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_company_form_unsent);
            setFormUnsent(false);
        }
    }, [firebase, formSent, formUnsent]);

    const initialValues = {
        entreprise: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
    };

    const signInSchema = Yup.object().shape({
        entreprise: Yup.string().required(t("business.contactForm.validationMessages.enterpriseRequired")),
        firstName: Yup.string().required(t("business.contactForm.validationMessages.firstNameRequired")),
        lastName: Yup.string().required(t("business.contactForm.validationMessages.lastNameRequired")),
        email: Yup.string().email(t("business.contactForm.validationMessages.validEmail")).required(t("business.contactForm.validationMessages.emailRequired")),
    });

    const fieldStyle = {
        fontWeight: "300",
        borderBottom: '2px solid #102543',
        padding: "8px",
        borderRadius: "2px",
        marginBottom: "8px",
        marginTop: "2px",
        backgroundColor: "#fff0",
        color: "#102543"
    };

    return (
        <div style={{ padding: "48px 0", borderRadius: "12px", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div style={{ width: mobile ? "clamp(0px, 70vw, 640px)" : "100%" }}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    onSubmit={(values, { resetForm }) => {
                        axios.post(`${process.env.GATSBY_MAILER_URL}/contact-form-mailer/rdv`, values)
                            .then(response => {
                                if (response.status === 201) {
                                    setFormSent(true);
                                    toast.info(t("business.contactForm.messageSent"));
                                    resetForm();
                                }
                            })
                            .catch(() => {
                                setFormUnsent(true);
                                toast.error(t("business.contactForm.errorOccurred"));
                            });
                    }}
                >
                    {({ isValid, dirty }) => (
                        <Form style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Field name="entreprise" placeholder={t("business.contactForm.placeholders.enterprise")} id="entreprise" style={fieldStyle} />
                            <ErrorMessage name="entreprise" component="div" className="happizErrorField" />

                            <Field name="lastName" placeholder={t("business.contactForm.placeholders.lastName")} id="lastName" style={fieldStyle} />
                            <ErrorMessage name="lastName" component="div" className="happizErrorField" />

                            <Field name="firstName" placeholder={t("business.contactForm.placeholders.firstName")} id="firstName" style={fieldStyle} />
                            <ErrorMessage name="firstName" component="div" className="happizErrorField" />

                            <Field name="phone" placeholder={t("business.contactForm.placeholders.phone")} id="phone" style={fieldStyle} />
                            <ErrorMessage name="phone" component="div" className="happizErrorField" />

                            <Field type="email" name="email" placeholder={t("business.contactForm.placeholders.email")} id="email" style={fieldStyle} />
                            <ErrorMessage name="email" component="div" className="happizErrorField" />

                            <div style={{ fontWeight: 200, fontSize: "14px" }}>
                                {t("business.contactForm.mentions")}
                            </div>

                            <button type="submit" disabled={!(dirty && isValid)} style={{
                                width: "100%",
                                height: "48px",
                                fontWeight: "500",
                                // border: `1px solid white`,
                                borderRadius: "999px",
                                padding: "6px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                background: "transparent linear-gradient(180deg, #366EC0 0%, #6288BF 100%) 0% 0% no-repeat padding-box",
                                cursor: dirty && isValid ? "pointer" : "not-allowed",
                                margin: "24px 0 0 0",
                                color: !(dirty && isValid) ? "#fff2" : "white",
                            }}>
                                {t("business.contactForm.submit")}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

const FormEntreprises = () => {
    const { t } = useTranslation();
    const { mobile } = useMobile();

    return (
        <WidthContainer center>
            <div style={{ padding: "64px 0px 48px 0", display: "grid", gridTemplateColumns: mobile ? "1fr" : "1fr 1fr", gap: mobile ? "24px" : "126px" }}>
                <div>
                    <h1 style={{ fontSize: !mobile ? "36px" : "20px", fontWeight: 700 }}>{t("business.experienceTitle")}</h1>
                    <h2 style={{ fontSize: !mobile ? "36px" : "20px", fontWeight: 700, color: "#366EC0" }}>{t("business.contactUs")}</h2>
                    <ContactForm />
                </div>
                <div style={{ display: "grid", placeItems: "center", marginTop: "-20%" }}>
                    <img src={sphere} alt="" style={{ gridArea: "center" }} />
                    <div style={{ gridArea: "center", color: "white", display: "grid", gridTemplateColumns: "auto auto", gap: "12px", alignItems: "center", fontSize: mobile ? "16px" : "24px" }}>
                        <BiMapPin style={{ color: "white" }} />
                        <span>{t("business.location")}</span>
                        <HiOutlineMail />
                        <span>contact@behappiz.com</span>
                    </div>
                </div>
            </div>
        </WidthContainer>
    );
};

export { FormEntreprises };
