import React, { ReactElement } from "react";
import { WidthContainer } from "../WidthContainer";
import Button from "./Button";
import Title from "./Title";

interface bannerProps {
    title:string;
    children: React.ReactNode;
    img:string;
    ctaAnchor:string;
    ctaAction:string;
    isReverse?:boolean;
    ctaIcon:ReactElement;
    addStyle?: string;
}

const Banner = ({title,children,img,ctaAnchor,ctaAction,ctaIcon,isReverse,addStyle}:bannerProps) => {
    return(
        <WidthContainer center>
            <div className={"grid md:grid-cols-12 gap-24 items-center mt-12 "+addStyle}>
                <div className="md:col-span-8 order-1 md:order-none flex flex-col items-center md:items-start">
                    <Title variant="md">{title}</Title>
                    <div className="mt-6 mb-12 text-2xl leading-normal">{children}</div>
                    <Button variant="arrow" to={ctaAction} icon={ctaIcon}>{ctaAnchor}</Button>
                </div>
                <img className={`rounded-[52px] md:col-span-4 ${isReverse && "md:-order-1"} w-3/5 m-auto md:w-full`} src={img} alt={"title"} />
            </div>
        </WidthContainer>
    )
}

export default Banner