import React from "react";
import { SVGWave } from "./SVGWave";

interface heroProps {
    title: string
    subtitle: string
    children?: React.ReactNode
    showcaseImg?: string
    img?: string
    video?: string
}

export const Hero = ({ title, subtitle, children, img, video, showcaseImg }: heroProps) => {
    return(
        <section style={img && !video ? {backgroundImage:`url(${img})`} : {}} className="h-screen relative overflow-hidden">
            <div className="container px-4 lg:px-0 mx-auto flex flex-col lg:flex-row items-center justify-center lg:justify-start h-full gap-12 z-20 relative">
                {showcaseImg && 
                <img className="w-2/3 lg:w-1/2" src={showcaseImg} alt={title} />
                }
                <div className={`text-center lg:text-left ${!showcaseImg && "lg:w-2/3"}`}>
                    <h1 className="text-[2rem] lg:text-[3.4rem] font-bold text-white drop-shadow-[0px_0px_10px_rgba(0,0,0,0.5)]">{title}</h1>
                    <h2 className="text-[1rem] lg:text-[1.7rem] text-white my-2 lg:my-6 drop-shadow-[0px_0px_10px_rgba(0,0,0,0.8)]">{subtitle}</h2>
                    <div className="flex flex-col md:flex-row gap-4 mt-4 justify-center items-center lg:justify-start">
                    {children}      
                    </div>             
                </div>
            </div>
            <div className="absolute -bottom-12 w-full z-10">
                <SVGWave/>
            </div>
            {video && 
            <video
            autoPlay
            muted
            playsInline
            loop
            poster={img}
            className="absolute w-full h-full top-0 left-0 z-0 object-cover"
            >
                <source src={video} type="video/mp4"/>
            </video>
            }
        </section>
    )
}
