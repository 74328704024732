import React from "react";
import { AiFillFire } from "react-icons/ai";
import { BsMoonStarsFill } from "react-icons/bs";
import { IoIosHeart } from "react-icons/io";
import StatCard from "../ui/StatCard";
import { useTranslation } from "gatsby-plugin-react-i18next"; // Make sure to import useTranslation

const ResultsWrapper = () => {
    const { t } = useTranslation(); // Use the useTranslation hook

    return (
        <div className="my-24 mx-[6%]">
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <StatCard icon={<BsMoonStarsFill className="w-24 h-auto fill-ink"/>} value={"86%"}>
                    {t("results.sleepImproved")} {/* Use t function for translations */}
                </StatCard>
                <StatCard icon={<AiFillFire className="w-24 h-auto fill-ink"/>} value={"82%"}>
                    {t("results.lessStressed")}
                </StatCard>
                <StatCard icon={<IoIosHeart className="w-24 h-auto fill-ink"/>} value={"88%"}>
                    {t("results.feelBetter")}
                </StatCard>
            </div>
            <p className="container mx-auto mt-8 text-center text-lg">
                {t("results.studyNote")}
            </p>
        </div>
    );
}

export default ResultsWrapper;
