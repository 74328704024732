import React, { ReactElement } from "react";

interface statProps {
    value: string;
    children: React.ReactNode;
    icon: ReactElement
}

const StatCard = ({icon,value,children}:statProps) => {
    return (
        <div className="bg-light rounded-[40px] p-8 flex flex-col justify-center items-center">
            <div className="mb-4">
                {icon}
            </div>
            <span className="font-medium text-[4rem] text-ink">{value}</span>
            <p className="text-ink text-xl text-center">{children}</p>
        </div>
    )
}

export default StatCard;