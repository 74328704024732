import React from "react";
import {useMobile} from "../../hooks/hooks";

interface titleProps {
    children: React.ReactNode;
    variant: "lg" | "md" | "sm";
    addStyle?: string;
}

const Title = ({children,variant,addStyle}:titleProps) => {
    const { mobile, tablet, medium } = useMobile()

    let titleComponent = <></>
    switch (variant) {
        case "lg":
            titleComponent = 
            <h1 className="z-10 font-bold leading-tight drop-shadow-xl text-6xl">
                {children}
            </h1>
        break;
        case "md":
            titleComponent =
            <h2 className="font-semibold leading-normal text-4xl">{children}</h2>
        break;
        case "sm":
            titleComponent =
            <h3 className="text-lg leading-normal">{children}</h3>
        break;
        default:
            titleComponent =
            <h1>{children}</h1>
        break;
    }

    return(
        <div className={addStyle}>
            {titleComponent}
        </div>
    )
}

export default Title